import { defaultStyles } from '@/utils/defaultStyles';
import { useEffect, useState } from 'react';
import BurgerIcon from './BurgerIcon';
import { getToken } from '@/utils/auth';

function NavButtons(props: { noHero?: boolean }) {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const token = getToken();
    setToken(token);
  }, []);

  return (
    <div className="flex flex-row">
      <div className="flex flex-col justify-center items-center pr-2 cursor-pointer">
        <div
          className={`w-[63px] h-[63px] rounded-full flex flex-row justify-center items-center cursor-pointer ${props.noHero ? 'bg-[#EFEFEF]' : 'bg-white'}`}
          onClick={() => {
            if (token) {
              window.location.assign('/dashboard');
              return;
            } else {
              window.location.assign('/login');
              return;
            }
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_469_645)">
              <path
                d="M18.1801 19.738H16.1801C16.1801 16.364 13.3501 13.62 9.86908 13.62C6.38808 13.62 3.55908 16.365 3.55908 19.738H1.55908C1.55908 15.26 5.28808 11.62 9.87008 11.62C14.4511 11.62 18.1801 15.26 18.1801 19.738Z"
                fill={token ? '#F47E01' : '#010002'}
              />
              <path
                d="M9.86999 10.97C6.84699 10.97 4.38599 8.508 4.38599 5.485C4.38499 2.461 6.84599 0 9.86999 0C12.895 0 15.356 2.46 15.356 5.485C15.356 8.51 12.895 10.97 9.86999 10.97ZM9.86999 2C7.94799 2 6.38499 3.563 6.38499 5.485C6.38499 7.407 7.94799 8.97 9.86999 8.97C11.793 8.97 13.356 7.407 13.356 5.485C13.356 3.563 11.791 2 9.86999 2Z"
                fill={token ? '#F47E01' : '#010002'}
              />
            </g>
            <defs>
              <clipPath id="clip0_469_645">
                <rect width="19.738" height="19.738" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {token && <div className="text-[#F47E01]">&#x2714;</div>}
        </div>
      </div>
      <div
        className="flex flex-col justify-center items-center pr-2 cursor-pointer"
        onClick={() =>
          (
            document.getElementById('checkout-modal') as HTMLDivElement | null
          )?.classList.remove('!hidden')
        }
      >
        <div
          className={`w-[63px] h-[63px] rounded-full flex flex-col justify-center items-center cursor-pointer ${props.noHero ? 'bg-[#EFEFEF]' : 'bg-white'}`}
        >
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.52002 2.52002H3.98162C4.88882 2.52002 5.60282 3.30122 5.52722 4.20002L4.83002 12.5664C4.71242 13.9356 5.79601 15.1116 7.17361 15.1116H16.1196C17.3292 15.1116 18.3876 14.1204 18.48 12.9192L18.9336 6.61922C19.0344 5.22482 17.976 4.09082 16.5732 4.09082H5.72883"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.4902 19.32C13.9103 19.32 13.4402 18.8499 13.4402 18.27C13.4402 17.6901 13.9103 17.22 14.4902 17.22C15.0701 17.22 15.5402 17.6901 15.5402 18.27C15.5402 18.8499 15.0701 19.32 14.4902 19.32Z"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.76997 19.32C7.19007 19.32 6.71997 18.8499 6.71997 18.27C6.71997 17.6901 7.19007 17.22 7.76997 17.22C8.34987 17.22 8.81997 17.6901 8.81997 18.27C8.81997 18.8499 8.34987 19.32 7.76997 19.32Z"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.40015 7.56H18.4801"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <button
        onClick={() =>
          window.open(
            'https://chat.whatsapp.com/LSSBOD0kvOiBYJgdvhYMbV',
            '_blank',
          )
        }
        className="cursor-pointer uppercase text-[16px] text-white font-semibold flex flex-row justify-center items-center bg-[#F47E01] outline-none border-none rounded-[63px] w-[140px] h-[63px]"
      >
        <div>Whatsapp</div>
      </button>
    </div>
  );
}

export const routes = [
  {
    title: 'Zamów',
    id: 'order',
  },
  {
    title: 'Jak to działa',
    id: 'work',
  },
  {
    title: 'Opinie',
    id: 'reviews',
  },
  {
    title: 'Kuchnia wiedzy',
    route: '/blog',
  },
  {
    title: 'Materiały',
    id: 'materials',
  },
  {
    title: 'Afiliacja',
    route: '/affiliation',
  },
];

function Navbar(props: { withoutHero?: boolean }) {
  const [isBurgerToggled, setIsBurgerToggled] = useState(false);

  return (
    <div className={`${props.withoutHero && 'shadow-lg'}`}>
      <div className={defaultStyles.padding}>
        <nav className={`relative navbar bg-transparent`}>
          <div className="navbar-start flex flex-row gap-4 z-40">
            <div
              onClick={() => window.location.assign('/')}
              className={`btn btn-ghost font-bold text-[20px] md:text-[25px] uppercase ${props.withoutHero ? 'text-black' : 'text-white'}`}
            >
              <img
                loading="lazy"
                src="/logo.png"
                alt="Pomarancze logo"
                className="w-[200px] h-[31px] lg:w-[300px] lg:h-[47px]"
              />
            </div>
            <div className="hidden 2xl:flex flex-row gap-2">
              {routes.map((item, index) => (
                <button
                  key={index}
                  className={`font-bold bg-transparent btn btn-ghost text-[14px] border border-solid border-transparent hover:border-[#F47E01] rounded-[68px] transition-all duration-300 cursor-pointer ${props.withoutHero ? 'text-black' : 'text-white'}`}
                  onClick={() => {
                    if (item.route) {
                      window.location.assign(item.route);
                    } else if (item.id) {
                      if (window.location.pathname != '/') {
                        window.location.assign('/');
                      }
                      document
                        .getElementById(item.id)!
                        .scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                >
                  {item.title}
                </button>
              ))}
            </div>
          </div>
          <div className="navbar-end flex 2xl:hidden flex-col justify-center items-end">
            <BurgerIcon
              isToggled={isBurgerToggled}
              onClick={() => setIsBurgerToggled(!isBurgerToggled)}
            />
          </div>
          {isBurgerToggled && (
            <div className="absolute top-[120%] left-0 flex flex-col gap-2 items-start px-2 pt-2 pb-12 backdrop-blur-md w-[90%] rounded-[24px]">
              {routes.map((item, index) => (
                <div
                  key={index}
                  className={`btn btn-ghost font-bold text-[16px] ${props.withoutHero ? 'text-black' : 'text-white'}`}
                  onClick={() => {
                    if (item.route) {
                      window.location.assign(item.route);
                    } else if (item.id) {
                      if (window.location.pathname != '/') {
                        window.location.assign('/');
                      }
                      document
                        .getElementById(item.id)!
                        .scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                >
                  {item.title}
                </div>
              ))}
              <NavButtons />
            </div>
          )}
          <div className="navbar-end hidden 2xl:flex flex-row md:gap-2 items-center z-10">
            <NavButtons noHero={props.withoutHero} />
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
